import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default function PublicationSummary(props) {
  return (
    <article>
      <Link to={props.publication.fields.slug} className="card-list-summary">
        <div className="card-list-summary--text">
          <header>{props.publication.frontmatter.title}</header>
          <p>{props.publication.frontmatter.publication_date}, {props.publication.frontmatter.publication_type}</p>
        </div>
        <div className="card-list-summary--images">
          {props.images.map(({id, childImageSharp, __typename}) => {
            if (childImageSharp) {
              return (
                <Img key={id} fixed={childImageSharp.fixed} alt={childImageSharp.originalName} />
              )
            } else return ""
          })}
        </div>
      </Link>
    </article>
  )
}
