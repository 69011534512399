import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PublicationSummary from "../components/publication-summary"


export default function Publications({ location, data }) {
  return (
    <Layout location={location} title="Publications">
      <h1>Publications</h1>
      <div className="list-summary">
        {data.content.publications.map(( node, index) => (
          <PublicationSummary key={node.id} publication={node} images={node.file.images}/>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
query Publications {
  content: allMarkdownRemark(filter: {fields: {slug: {glob: "/publications/**/*"}}}, sort: {fields: frontmatter___publication_date, order: DESC}) {
    publications: nodes {
      id
      fields {
        slug
      }
      frontmatter {
        title
        publication_date(formatString: "Mo MMMM YYYY")
        publication_type
      }
      file: parent {
        id
        ... on File {
          id
          name
          base
          images: children {
            id
            ... on File {
              id
              childImageSharp {
                fixed(width: 144, height: 144, fit: INSIDE) {
                  src
                  height
                  width
                  originalName
                  base64
                  srcSet
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
